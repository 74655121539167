<template>
  <div>
    <general-table
      ref="companiesTable"
      :api-url="apiUrl"
      :add-type="addType"
      :columns="columns"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :viw-component="viwComponent"
      :view-content="false"
      :type="type"
      :selectable="false"
    />

  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/companies',
      addType: 'Add New company',
      type: 'page',
      viewContent: false,
      filter: {},
      countries: '',
      types: '',
      addComponentName: 'add-shipping-companies',
      editComponent: 'edit-shipping-companies',
      viwComponent: 'show-shipping-companies',
      columns: [
        { key: 'name_ar', label: 'Arabic Name' },
        { key: 'name_en', label: 'English Name' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/companies?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  mounted() {
    this.getCountries()
  },
  methods: {

    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },

  },

}
</script>

<style>

</style>
